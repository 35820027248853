import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
      <header>
        <p style={{ fontSize: 100}}>🎨</p>        
      </header>
    </div>
  );
}

export default App;
